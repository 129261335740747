<template>
  <v-card>
    <loader :loading="isLoading" :size="$appConfig.loader.small" />
    <v-card-title id="deliverability-matrics-title" class="px-8 pt-8 pb-4">
      <h5
        class="text-left subtitle-1 text--primary font--secondary font-weight-large"
      >
        {{ $dictionary.app.accountCampaigns.index.subTitle }}
      </h5>
    </v-card-title>
    <v-divider class="border-light-grey" />
    <v-card-text id="deliverability-matrics__content" class="px-8 pb-6 pt-6">
      <Content name="Subject Line" :value="campaign.subject" />
      <Content name="Campaign ID" :value="campaign.id" />
      <Content
        name="First Seen"
        :value="formatDateToAmPm(campaign.first_seen)"
      />
      <Content name="From Domain" :value="campaign.from_domain" />
      <Content
        name="Duration"
        :value="duration(campaign.first_seen, campaign.last_seen)"
      />
    </v-card-text>
    <v-divider class="border-light-grey" />
  </v-card>
</template>

<script>
import { Content } from "./shared";
import { duration, formatDateToAmPm } from "@/filters";

/**
 * Displays campaign details
 */
export default {
  name: "CampaignDetails",
  /**
   * ---------------- Component ------------------
   */
  components: {
    Content,
  },
  /**
   * ---------------- Props ------------------
   */
  props: {
    /**
     * Campaign details
     */
    campaign: {
      type: Object,
      required: true,
    },
    isLoading: { type: Boolean, required: true, default: false },
  },
  /**
   * ---------------- Methods ------------------
   */
  methods: {
    duration,
    formatDateToAmPm,
  },
};
</script>
