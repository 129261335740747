var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"mailbox-providers"}},[_c('page-header',{attrs:{"breadcrumbs":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-col',{staticClass:"pb-0 mt-5",attrs:{"md":"12","lg":"6"}},[_c('h5',{staticClass:"text-h5 font-weight-large text--primary font--secondary",attrs:{"id":"mailbox-providers__title"}},[_vm._v(" "+_vm._s(_vm.$dictionary.app.mailboxProviders.index.title)+" ")])]),_c('v-col',{staticClass:"d-flex justify-end align-center pb-0",attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"mx-3 my-lg-0 my-3 mailbox-filters-btn"},[_c('tooltip',{attrs:{"name":"tool"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('filter-button',_vm._g(_vm._b({attrs:{"isFilterApplied":_vm.areFiltersPersisted},on:{"toggle-right-bar":function($event){return _vm.toggleFilterDrawer(!_vm.isRightDrawerOpen)}}},'filter-button',attrs,false),on))]}}])})],1),_c('csv-button',{attrs:{"data":_vm.sortedRecords,"fileName":"mailboxProviders","loading":_vm.isLoading,"fields":_vm.csvFields,"labels":_vm.labels}})],1)]},proxy:true}])}),_c('v-row',{attrs:{"id":"deliverability-matrics"}},[_c('v-col',{staticClass:"mt-4 pr-lg-6",attrs:{"lg":"3","md":"12","cols":"12","id":"campaigns-detail"}},[_c('campaign-details',{attrs:{"is-loading":_vm.isCampaignLoading,"campaign":_vm.campaign}})],1),_c('v-col',{attrs:{"md":"12","lg":"9","id":"deliverability-matrics__content py-6"}},[_c('v-row',{staticClass:"my-2"},[_vm._l((_vm.campaignMetrics),function(ref,i){
var percent = ref.percent;
var count = ref.count;
var name = ref.name;
var icon = ref.icon;
var color = ref.color;
var iconClass = ref.iconClass;
return [_c('v-col',{key:i,attrs:{"lg":"3","md":"3","cols":"12"}},[_c('card-tile',{attrs:{"text":name,"percent":percent,"value":count || 0,"icon":icon,"color":color,"isLoading":_vm.isLoading,"iconClass":iconClass}})],1)]})],2),_c('mailbox-providers-table',{attrs:{"headers":_vm.headers,"isLoading":_vm.isLoading,"mailbox-providers":_vm.mailboxProvider},on:{"sorted-records":_vm.updateSortedRecords,"navigate-to-Seeds":_vm.emailAddressSeedsNavigator}}),_c('div',{attrs:{"id":"isp-filter__drawer"}},[_c('right-side-bar',{attrs:{"app":"","prepend":"","clipped":"","isAbsolute":false,"isRightDrawerOpen":_vm.isRightDrawerOpen},on:{"toggle-right-bar":_vm.toggleFilterDrawer}},[_c('isp-filter-form',{attrs:{"getSendingIPs":_vm.getSendingIPsList,"set-filters":_vm.setMailboxProviderFilterSettings,"sending-ips":_vm.sendingIPs},on:{"apply-filters":_vm.getFilteredIspRecords,"remove-filters":_vm.removeFilter}})],1)],1)],1)],1),_c('snackbar',{attrs:{"snackBarType":_vm.$appConfig.snackbar.snackbarTypes.error,"snackbarValue":_vm.hasError},on:{"close":function($event){_vm.hasError = !_vm.hasError}}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }