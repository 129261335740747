<template>
  <v-btn
    text
    outlined
    class="mx-1 text-dark-grey btn-light-grey-border"
    @click.stop="toggleDrawer"
    v-if="!isFilterApplied"
  >
    <v-icon class="text-dark-grey" left>mdi-filter-variant</v-icon>
    <span class="my-1 text-dark-grey font-weight-bold">Filter</span>
  </v-btn>
  <v-btn
    class="mx-1 text-dark-grey btn-light-grey-border"
    text
    outlined
    @click.stop="toggleDrawer"
    v-else
  >
    <v-icon class="text-dark-grey" left>mdi-close</v-icon>
    <span class="my-1 text-dark-grey font-weight-bold">Filter Applied</span>
  </v-btn>
</template>

<script>
/**
 * Filter button to be used to apply the filters in the application
 */
export default {
  name: "FilterButton",
  /**
   * ----------------- Props ------------------
   */
  props: {
    isFilterApplied: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  /**
   * ----------------- Custom events -----------------
   */
  emits: ["toggle-right-bar"],
  /**
   * ----------------- Methods -----------------
   */
  methods: {
    /**
     * Emits event in the parent component to toggle the drawer
     * @emits toggle-right-bar
     */
    toggleDrawer() {
      this.$emit("toggle-right-bar");
    },
  },
};
</script>