<template>
  <section id="mailbox-providers">
    <page-header :breadcrumbs="breadcrumbs">
      <template #title>
        <v-col md="12" lg="6" class="pb-0 mt-5">
          <h5
            id="mailbox-providers__title"
            class="text-h5 font-weight-large text--primary font--secondary"
          >
            {{ $dictionary.app.mailboxProviders.index.title }}
          </h5>
        </v-col>
        <v-col cols="12" lg="6" class="d-flex justify-end align-center pb-0">
          <div class="mx-3 my-lg-0 my-3 mailbox-filters-btn">
            <tooltip #default="{ on, attrs }" name="tool">
              <filter-button
                v-on="on"
                v-bind="attrs"
                :isFilterApplied="areFiltersPersisted"
                @toggle-right-bar="toggleFilterDrawer(!isRightDrawerOpen)"
              />
            </tooltip>
          </div>
          <csv-button
            :data="sortedRecords"
            fileName="mailboxProviders"
            :loading="isLoading"
            :fields="csvFields"
            :labels="labels"
          />
        </v-col>
      </template>
    </page-header>

    <v-row id="deliverability-matrics">
      <v-col
        lg="3"
        md="12"
        cols="12"
        id="campaigns-detail"
        class="mt-4 pr-lg-6"
      >
        <campaign-details
          :is-loading="isCampaignLoading"
          :campaign="campaign"
        />
      </v-col>
      <v-col md="12" lg="9" id="deliverability-matrics__content py-6">
        <v-row class="my-2">
          <template
            v-for="({ percent, count, name, icon, color, iconClass },
            i) in campaignMetrics"
          >
            <v-col :key="i" lg="3" md="3" cols="12">
              <card-tile
                :text="name"
                :percent="percent"
                :value="count || 0"
                :icon="icon"
                :color="color"
                :isLoading="isLoading"
                :iconClass="iconClass"
              />
            </v-col>
          </template>
        </v-row>

        <mailbox-providers-table
          :headers="headers"
          :isLoading="isLoading"
          @sorted-records="updateSortedRecords"
          :mailbox-providers="mailboxProvider"
          @navigate-to-Seeds="emailAddressSeedsNavigator"
        />
        <div id="isp-filter__drawer">
          <right-side-bar
            app
            prepend
            clipped
            :isAbsolute="false"
            :isRightDrawerOpen="isRightDrawerOpen"
            @toggle-right-bar="toggleFilterDrawer"
          >
            <isp-filter-form
              @apply-filters="getFilteredIspRecords"
              :getSendingIPs="getSendingIPsList"
              :set-filters="setMailboxProviderFilterSettings"
              @remove-filters="removeFilter"
              :sending-ips="sendingIPs"
            />
          </right-side-bar>
        </div>
      </v-col>
    </v-row>

    <!----------------------- Snackbar starts ----------------------->
    <snackbar
      :snackBarType="$appConfig.snackbar.snackbarTypes.error"
      :snackbarValue="hasError"
      @close="hasError = !hasError"
    >
      {{ errorMessage }}
    </snackbar>
    <!-- Snackbar ends -->
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { MailboxProvidersTable } from "@/components/tables";
import { CardTile, Tooltip, PageHeader } from "@/components/shared";
import { CsvButton } from "@/components/widgets";
import { CampaignDetails, FilterButton } from "@/components";
import { mailboxProvidersHeaders as headers } from "@/data/table-headers";
import { RightSideBar } from "@/components/drawers";
import { IspFilterForm } from "@/components/forms";
import {
  getPropArr,
  hasAnyPropsValue,
  isEmpty as isArrayEmpty,
  getMailBoxProviderSeedCount,
} from "@/utils/common.utils";
import { TABLE_METRICS_TILE_COLORS } from "@/constants";
import { getAccountCampaign } from "@/services";
import { getMailboxProviderStats } from "@/utils/shared.utils";

/**
 * Mailbox provider page
 */
export default {
  name: "MailboxProviders",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: {
    MailboxProvidersTable,
    CsvButton,
    CardTile,
    CampaignDetails,
    RightSideBar,
    FilterButton,
    Tooltip,
    IspFilterForm,
    PageHeader,
  },
  /**
  |--------------------------------------------------
  | Data properties
  |--------------------------------------------------
  */
  data() {
    return {
      isLoading: false,
      errorMessage: null,
      hasError: false,
      headers,
      labels: {
        mailbox_provider: "Mailbox",
        inbox_count: "Inbox",
        inbox: "Inbox %",
        spam_count: "Spam",
        spam: "Spam %",
        missing_count: "Missing",
        missing: "Missing %",
        first_seen: "First Seen",
        duration: "Duration",
      },
      mailboxProvider: [],
      sortedRecords: this.mailboxProvider ?? [],
      isFilterApplied: false,
      isRightDrawerOpen: false,
      campaign: {},
      isCampaignLoading: false,
    };
  },
  /**
   * Before route change handler
   * Dispacthes action in store to remove the persisted filters on route change
   */
  beforeRouteLeave(to, from, next) {
    this.setMailboxProviderSettings({ filters: {}, areFiltersApplied: false });
    next();
  },
  /**
   * ---------------- Computed properties ------------------
   */
  computed: {
    ...mapGetters({
      mailboxProviders: "account/filteredMailboxProviders",
      sendingIPs: "campaign/sendingIps",
      selectedAccount: "ui/selectedAccount",
      mailboxProviderSettings: "settings/mailboxProvider",
    }),

    /**
     * Request Query params to be used to fetch the mailbox providers of the campaign
     * @type {Object} - campaignParams
     */
    campaignParams() {
      return {
        account_id: this.routeParams.id,
        campaign_id: this.routeParams.campaignId,
      };
    },
    /**
     * Breadcrumbs routes and names which will be shown at the top of the page
     * @type {Array}
     */
    breadcrumbs() {
      return [
        {
          to: this.selectedAccount.path,
          title: this.$dictionary.app.accountCampaigns.index.title,
          type: this.$appConfig.breadcrumbs.types.route,
        },
        {
          title: this.$dictionary.app.mailboxProviders.index.title,
          type: this.$appConfig.breadcrumbs.types.route,
        },
      ];
    },
    /**
     * View routes params
     * @type {Object} contains all the route params of this view
     */
    routeParams() {
      return this.$route.params;
    },
    routeQuery() {
      return this.$route.query;
    },
    csvFields() {
      return Object.keys(this.labels);
    },
    /**
     * Camapign metrics details
     * @type {Array}
     */
    campaignMetrics() {
      return [
        {
          name: "Inbox",
          color: TABLE_METRICS_TILE_COLORS.green,
          icon: "mdi-inbox-arrow-down",
          ...this.getTileCountPercentage("inbox_count"),
        },
        {
          name: "Spam",
          color: TABLE_METRICS_TILE_COLORS.yellow,
          icon: "report",
          iconClass: "material-icons-outlined",
          ...this.getTileCountPercentage("spam_count"),
        },
        {
          name: "Missing",
          color: TABLE_METRICS_TILE_COLORS.magenta,
          icon: "mdi-close-circle",
          ...this.getTileCountPercentage("missing_count"),
        },
        {
          name: "Sending IPs",
          color: TABLE_METRICS_TILE_COLORS.darkBlue,
          icon: "mdi-send",
          percent: this.sendingIPs?.length ?? 0,
        },
      ];
    },
    /**
     * Total seeds count of the selected campaign
     */
    totalSeedsCount() {
      let totalSeedCount = getMailBoxProviderSeedCount(this.mailboxProvider);
      totalSeedCount = totalSeedCount || 0;
      return totalSeedCount;
    },
    /**
     * Persistes applied filters
     */
    appliedFilters() {
      if (hasAnyPropsValue(this.mailboxProviderSettings?.filters)) {
        const {
          seed_domains,
          sending_ips,
        } = this.mailboxProviderSettings?.filters;
        return {
          seed_domains:
            seed_domains && !isArrayEmpty(seed_domains)
              ? seed_domains?.join(",")
              : null,
          sending_ips:
            sending_ips && !isArrayEmpty(sending_ips)
              ? sending_ips?.join(",")
              : null,
        };
      }
      return {};
    },
    /**
     * Detemrines that are filters applied on the mailbox providers
     * @type {Boolean}
     */
    areFiltersPersisted() {
      return (
        this.mailboxProviderSettings?.areFiltersApplied &&
        hasAnyPropsValue(this.mailboxProviderSettings.filters)
      );
    },
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      getCampaignMailboxProviders: "account/getCampaignMailboxProviders",
      getSendingIPs: "campaign/getCampaignSendingIps",
      setMailboxProviderSettings: "settings/setMailboxProviderSettings",
    }), 
    /**
     * Navigates to the email address seeds page
     * @param {String} seedDomain - Seed Domain whose seeds are to be viewed
     */
    emailAddressSeedsNavigator(seedDomain) {
      const { path } = this.$route;

      const navigateTo = `${path}/${seedDomain}${this.$paths.seeds}`;
      this.$router.push({ path: navigateTo });
    },
    /**
     * Fetches campaign email address providers from the store
     */
    async getCampaignMailboxProvidersList(params) {
      try {
        const reqParams = { ...params, ...this.campaignParams };
        this.isLoading = true;
        await this.getCampaignMailboxProviders(reqParams);
      } catch (error) {
        this.error = true;
        this.errorMessage = error.message;
      } finally {
        this.isLoading = false;
      }
    },
    /**
     * Calculates the tile percentage
     * @param {String} prop - Prop name
     * @returns {Number} Percentage
     */
    getTileCountPercentage(prop) {
      let propArr = getPropArr(this.mailboxProvider, prop);

      let count = propArr && propArr.length > 0 ? this.$sum(propArr) : 0;
      let percent = this.totalSeedsCount
        ? `${this.$options.filters.percent(count, this.totalSeedsCount)}%`
        : `${0}%`;

      return { count, percent };
    },
    /**
     * Sorted records
     */
    updateSortedRecords(records) {
      this.sortedRecords = records;
    },
    /**
     * Fetches the list of sending ip address
     */
    async getSendingIPsList() {
      let params = {
        ...this.campaignParams,
      };
      await this.getSendingIPs(params);
    },
    /**
     * Toogle the filter drawer
     */
    toggleFilterDrawer(val) {
      this.isRightDrawerOpen = val;
    },
    /**
     * Fetched filtered isp data from the api
     */
    /* eslint-disable no-unused-vars */
    async getFilteredIspRecords(data, filters) {
      this.setMailboxProviderSettings({ areFiltersApplied: true, filters });
      this.toggleFilterDrawer(false);
      await this.filterRecords(filters);
    },
    /**
     * Filters mailbox provider records on the basis of the filters applied
     */
    async filterRecords({ seed_domains, sending_ips }) {
      let emails = [];
      if (!isArrayEmpty(seed_domains) && !isArrayEmpty(sending_ips)) {
        emails = this.mailboxProviders.filter(
          (email) =>
            seed_domains.includes(email.seed_domain) &&
            sending_ips.includes(email.sending_ip)
        );
      } else if (!isArrayEmpty(seed_domains)) {
        emails = this.computeFilteredResult(seed_domains, "seed_domain");
      } else if (!isArrayEmpty(sending_ips)) {
        emails = this.computeFilteredResult(sending_ips, "sending_ip");
      } else {
        emails = this.mailboxProviders;
      }
      this.mailboxProvider = getMailboxProviderStats(emails);
    },
    /**
     * Computes mailbox provider filtered result
     * @returns {Array} Filtered emails
     */
    computeFilteredResult(filterArr, uniqProp) {
      return this.mailboxProviders.filter((email) =>
        filterArr.includes(email[uniqProp])
      );
    },
    /**
     * Removes the filters applied on the mailbox providers table
     */
    removeFilter() {
      this.setMailboxProviderSettings({ areFiltersApplied: false });
      this.mailboxProvider = getMailboxProviderStats(this.mailboxProviders);
      this.toggleFilterDrawer(false);
    },
    /**
     * Sets the mailbox provider filter and dispactes the action in the store s
     */
    setMailboxProviderFilterSettings(filters) {
      this.setMailboxProviderSettings({ filters });
    },
    /**
     *  Fetch Campaign details
     */
    async getCampaignDetails() {
      try {
        this.isCampaignLoading = true;

        const params = {
          account_id: this.routeParams.id,
          id: this.routeParams.campaignId,
        };

        let { data } = await getAccountCampaign(params);
        data = data ?? {};

        this.campaign = data;
        this.isCampaignLoading = false;
      } catch {
        this.isCampaignLoading = false;
      }
    },
  },
  /**
  |--------------------------------------------------
  | Before mount lifecycle hook
  |--------------------------------------------------
  */
  beforeMount() {
    this.getSendingIPsList();
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  async mounted() {
    this.getCampaignDetails();
    await this.getCampaignMailboxProvidersList(this.appliedFilters);

    if (hasAnyPropsValue(this.mailboxProviderSettings?.filters)) {
      this.filterRecords(this.mailboxProviderSettings?.filters);
    } else {
      this.mailboxProvider = getMailboxProviderStats(this.mailboxProviders);
    }
  },
};
</script>
